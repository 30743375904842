@import '../shared/style-guide';

.body {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .mainImageContainer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    .mainImage {
      transition: border-color 0.5s, border-width 0.5s;
      border: 0px solid $primary-tone-light;
      width: 40%;
      border-radius: 1000px;
    }
    .mainImageBlur {
      &:hover {
        cursor: pointer;
        +.mainImage {
          border: 16px solid $color-primary-500;
        }
      }
      width: 40%;
      position: absolute;
      bottom: 0px;
      top: 0px;
      box-shadow: 0 0 100px 50px $primary-tone-light inset;
      border-radius: 1000px;
    }
  }
}