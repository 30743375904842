.footer-space {
  height: 140px;
}
.footer {
  font-family: 'Neucha', 'Verdana', sans-serif;
  width: 100%;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
  /* background-color: rgba(255,255,255,0.5); */
  position: fixed;
  height: 100px;
  bottom: 0px;
  /* top: 80px; */
  pointer-events: none;
}
.footer:hover {
  filter: blur(0px);
}
.footer a {
  pointer-events: all;
  font-size: 2rem;
}
.footer .social {
  filter: blur(6px);
  transition: filter 0.5s;
}
.footer .social img {
  height: 60px;
}
.footer .social:hover {
  filter: blur(0);
}