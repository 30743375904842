@import './style-guide';

/* Animations */
/*------------*/

/* Spinning Mobile */
$spinning-mobile-position: 32px 0;
$spinning-mobile-size: 50%;
@keyframes spinning-mobile-animation {
  0% {
    background: url('/assets/spinning_mobile/mobile_1.svg') no-repeat $spinning-mobile-position;
    background-size: $spinning-mobile-size;
  }
  10% {
    background: url('/assets/spinning_mobile/mobile_2.svg') no-repeat $spinning-mobile-position;
    background-size: $spinning-mobile-size;
  }
  20% {
    background: url('/assets/spinning_mobile/mobile_3.svg') no-repeat $spinning-mobile-position;
    background-size: $spinning-mobile-size;
  }
  30% {
    background: url('/assets/spinning_mobile/mobile_4.svg') no-repeat $spinning-mobile-position;
    background-size: $spinning-mobile-size;
  }
  40% {
    background: url('/assets/spinning_mobile/mobile_5.svg') no-repeat $spinning-mobile-position;
    background-size: $spinning-mobile-size;
  }
  50% {
    background: url('/assets/spinning_mobile/mobile_6.svg') no-repeat $spinning-mobile-position;
    background-size: $spinning-mobile-size;
  }
  60% {
    background: url('/assets/spinning_mobile/mobile_7.svg') no-repeat $spinning-mobile-position;
    background-size: $spinning-mobile-size;
  }
  70% {
    background: url('/assets/spinning_mobile/mobile_8.svg') no-repeat $spinning-mobile-position;
    background-size: $spinning-mobile-size;
  }
  80% {
    background: url('/assets/spinning_mobile/mobile_9.svg') no-repeat $spinning-mobile-position;
    background-size: $spinning-mobile-size;
  }
  90% {
    background: url('/assets/spinning_mobile/mobile_10.svg') no-repeat $spinning-mobile-position;
    background-size: $spinning-mobile-size;
  }
  100% {
    background: url('/assets/spinning_mobile/mobile_1.svg') no-repeat $spinning-mobile-position;
    background-size: $spinning-mobile-size;
  }
}