
/* Primary settings */
/*------------------*/

/* Screen widths */
$screen-small: 500px;

/* Colors */
$text-tone-medium: #888888;
$text-tone-dark: #555555;
$primary-tone-dark: #dedede;
$primary-tone-light: #f5f5f5;

$color-primary-100: #DEF6C6;
$color-primary-200: #B8ED92;
$color-primary-300: #7ECB55;
$color-primary-400: #48982A;
$color-primary-500: #135403;
$color-primary-600: #0A4802;
$color-primary-700: #043C01;
$color-primary-800: #003002;
$color-primary-900: #002804;
$color-primary-transparent-100: rgba(19, 84, 3, 0.08);
$color-primary-transparent-200: rgba(19, 84, 3, 0.16);
$color-primary-transparent-300: rgba(19, 84, 3, 0.24);
$color-primary-transparent-400: rgba(19, 84, 3, 0.32);
$color-primary-transparent-500: rgba(19, 84, 3, 0.4);
$color-primary-transparent-600: rgba(19, 84, 3, 0.48);
$color-success-100: #D0FBD0;
$color-success-200: #A2F7AC;
$color-success-300: #71E78A;
$color-success-400: #4BD075;
$color-success-500: #1AB259;
$color-success-600: #139957;
$color-success-700: #0D8053;
$color-success-800: #08674C;
$color-success-900: #045546;
$color-success-transparent-100: rgba(26, 178, 89, 0.08);
$color-success-transparent-200: rgba(26, 178, 89, 0.16);
$color-success-transparent-300: rgba(26, 178, 89, 0.24);
$color-success-transparent-400: rgba(26, 178, 89, 0.32);
$color-success-transparent-500: rgba(26, 178, 89, 0.4);
$color-success-transparent-600: rgba(26, 178, 89, 0.48);
$color-info-100: #CFE4FD;
$color-info-200: #A1C7FC;
$color-info-300: #70A4F6;
$color-info-400: #4C85ED;
$color-info-500: #1657E2;
$color-info-600: #1043C2;
$color-info-700: #0B31A2;
$color-info-800: #072283;
$color-info-900: #04176C;
$color-info-transparent-100: rgba(22, 87, 226, 0.08);
$color-info-transparent-200: rgba(22, 87, 226, 0.16);
$color-info-transparent-300: rgba(22, 87, 226, 0.24);
$color-info-transparent-400: rgba(22, 87, 226, 0.32);
$color-info-transparent-500: rgba(22, 87, 226, 0.4);
$color-info-transparent-600: rgba(22, 87, 226, 0.48);
$color-warning-100: #FDFCCD;
$color-warning-200: #FBFA9C;
$color-warning-300: #F4F26A;
$color-warning-400: #E9E644;
$color-warning-500: #DBD70D;
$color-warning-600: #BCB809;
$color-warning-700: #9D9A06;
$color-warning-800: #7F7B04;
$color-warning-900: #696602;
$color-warning-transparent-100: rgba(219, 215, 13, 0.08);
$color-warning-transparent-200: rgba(219, 215, 13, 0.16);
$color-warning-transparent-300: rgba(219, 215, 13, 0.24);
$color-warning-transparent-400: rgba(219, 215, 13, 0.32);
$color-warning-transparent-500: rgba(219, 215, 13, 0.4);
$color-warning-transparent-600: rgba(219, 215, 13, 0.48);
$color-danger-100: #FDE9D1;
$color-danger-200: #FCCFA4;
$color-danger-300: #F6AB75;
$color-danger-400: #ED8952;
$color-danger-500: #E2551D;
$color-danger-600: #C23A15;
$color-danger-700: #A2240E;
$color-danger-800: #831209;
$color-danger-900: #6C0605;
$color-danger-transparent-100: rgba(226, 85, 29, 0.08);
$color-danger-transparent-200: rgba(226, 85, 29, 0.16);
$color-danger-transparent-300: rgba(226, 85, 29, 0.24);
$color-danger-transparent-400: rgba(226, 85, 29, 0.32);
$color-danger-transparent-500: rgba(226, 85, 29, 0.4);
$color-danger-transparent-600: rgba(226, 85, 29, 0.48);

/* Menu bar */
$menu-bar-height: 80px;
$logo-height-multiplier: 0.7;


/* Fonts */
/*-------*/

/* Neucha */
@import url('https://fonts.googleapis.com/css?family=Neucha&display=swap');
$title-font: 'Neucha', 'Verdana', sans-serif;
