@import './styles/shared/style-guide';
@import 'primereact/resources/themes/saga-blue/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';

body {
  background-color: $primary-tone-light;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: $color-primary-500;
  &:hover {
    color: $color-primary-400;
  }
}


.p-galleria-thumbnail-container {
  margin: 0 auto;
  border-radius: 20px;
}
.p-galleria-thumbnail-item {
  overflow: hidden;
}
.p-galleria-thumbnail-items {
  transform: none !important;
}