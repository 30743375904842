@import '../shared/style-guide';

.product {
  margin: 10px;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 0 20px 0px rgba(0,0,0,0.1);
  background-color: $primary-tone-dark;
  cursor: pointer;
  @keyframes expand {
    from {
      height: 0;
      opacity: 0;
    }
    to{
      height: 20px;
      opacity: 1;
      margin-top: 16px;
    }
  }
  @keyframes shrink {
    from {
      opacity: 1;
      height: 20px;
      margin-top: 16px;
    }
    to{
      height: 0;
      opacity: 0;
    }
  }
  .title {
    animation-fill-mode: forwards;
    transition: height 2s, padding 2s, margin 2s;
    &:not(.expand) {
      animation-name: shrink;
      animation-duration: 0.15s;
      animation-fill-mode: both;
      visibility: hidden;
      animation-delay: 0.2s;
    }
    &.expand {
      visibility: visible;
      animation-name: expand;
      animation-duration: 0.2s;
      animation-delay: 0.0s;
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-family: $title-font;
      color: $text-tone-medium;
      &:hover {
        .moreButton {
          text-shadow: 0 0 4px rgba(0,0,0,0.25);
        }
      }
    }
  }
  &:hover {
    background-color: $primary-tone-light;
    box-shadow: 0 0 10px 0px rgba(0,0,0,0.2);
  }
  .image {
    height: 24vw;
    background-position: top;
    background-size: cover;
  }
}