@import '../shared/style-guide';
@import '../shared/breathing-arrow';
@import '../shared/animations';

.headerSpace {
  @media screen and (max-width: $screen-small) {
    height: 10px;
  }
  height: $menu-bar-height;
}

.pageHeader {
  position: fixed;
  width: fit-content;
  display: flex;
  align-items: center;
  padding-left: 8px;
  height: $menu-bar-height;
  .logo {
    height: calc(#{$menu-bar-height} * #{$logo-height-multiplier});
  }
  .pageName {
    font-family: $title-font;
    font-size: 40px;
    margin-left: 10px;
    color: $text-tone-medium;
    position: relative;
    top: 4px;
  }
}
.menuContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  @media screen and (max-width: $screen-small) {
    margin-top: 10px;
  }
}
.slidingMenu {
  @media screen and (max-width: $screen-small) {
    padding-top: 30px;
    max-height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: static;
    background-color: $primary-tone-light;
  }
  width: 100%;
  z-index: 1;
  overflow-y: hidden;
  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  max-height: 0;
  &.open { max-height: $menu-bar-height; }
  background: $primary-tone-dark;
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  font-family: $title-font;
  a { 
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    &:hover {
      text-shadow: 2px 2px 20px white;
      .siteName {
        color: $text-tone-dark;
      }
      .logo {
        // border: 4px solid $text-tone-dark;
        border: 4px solid $color-warning-600;
        border-radius: 50px;
        padding: 0;
      }
    }
  }
  .logoContainer {
    @media screen and (max-width: $screen-small) {
      padding-left: 0;
    }
    width: 22%;
    padding-left: 8px;
    display: flex;
    align-items: flex-end;
    * {
      position: relative;
      bottom: 13px;
    }
    .logo {
      height: calc(#{$menu-bar-height} * #{$logo-height-multiplier});
      transition: border 0.25s;
    }
    .siteName {
      font-size: 40px;
      margin-left: 10px;
      color: $text-tone-medium;
    }
  }
  .primaryNavContainer {
    @media screen and (max-width: $screen-small) {
      width: 100%;
      background-color: $primary-tone-dark;
      justify-content: space-around;
      padding-top: 10px;
    }
    width: 30%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .navItem {
      @media screen and (max-width: $screen-small) {
        min-width: 50%;
        display: flex;
        justify-content: center;
      }
      .preloader {
        background: url('/assets/lamp-on.svg') no-repeat 80% -100%;
        animation: spinning-mobile-animation 1s infinite;
      }
      .icon {
        position: relative;
        cursor: pointer;
        top: -10px;
        height: $menu-bar-height;
        &.lamps {
          width: 90px;
          background: url('/assets/lamp.svg') no-repeat 80% -100%;
          background-size: 90%;
          .name {
            left: 30px;
          }
          &:hover {
            background: url('/assets/lamp-on.svg') no-repeat 80% -100%;
            background-size: 90%;
          }
        }
        &.mobiles {
          width: 120px;
          animation: spinning-mobile-animation 3s infinite;
          animation-play-state: paused;
          .name {
            left: 20px;
          }
          &:hover {
            animation-play-state: running;
          }
        }
      }
    }
    .name {
      position: relative;
      top: 44px;
      font-size: 30px;
    }
  }
  .secondaryNavContainer {
    @media screen and (max-width: $screen-small) {
      width: 100%;
      padding-top: 30px;
      background-color: $primary-tone-dark;
      align-items: center;
      justify-content: center;
      padding-right: 0;
    }
    width: 20%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-right: 28px;
    .navItem {
      min-width: 50%;
      position: relative;
      bottom: 14px;
      font-size: 1em;
      color: $text-tone-dark;
      padding: 20px;
      a {
        @media screen {
          justify-content: center;
        }
      }
    }
  }
}