@import './style-guide.scss';

.breathingArrow {
  @media screen and (max-width: $screen-small) {
    display: none;
  }
  z-index: 999;
	position: fixed;
	background-color: $primary-tone-dark;
  text-align: left;
  top: 30px;
  left: 48%;
  filter: blur(1px);
}
.breathingArrow:before,
.breathingArrow:after {
	content: '';
	position: absolute;
	background-color: inherit;
}
.breathingArrow,
.breathingArrow:before,
.breathingArrow:after {
  cursor: pointer;
  border-top-right-radius: 10px;
  animation: breathingArrow 10s infinite;
  animation-timing-function: ease-in-out;
}

.breathingArrow {
	transform: rotate(-240deg) skewX(-30deg) scale(1,.866);
}
.breathingArrow:before {
	transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
}
.breathingArrow:after {
	transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
}

/* Breathing Arrow */
@keyframes breathingArrow {
  from {
    border-top-right-radius: 10px;
    width: 1.5rem;
    height: 1.5rem;
  }
  50% {
    border-top-right-radius: 20px;
    width: 1.25rem;
    height: 1.25rem;
  }
  to {
    border-top-right-radius: 10px;
    width: 1.5rem;
    height: 1.5rem;
  }
}