@import '../shared/style-guide';
$gutter: 20px;

.collection {
  @media screen and (max-width: $screen-small) {
    grid-template-columns: 1fr 1fr;
  }
  margin: 0 10vw; 
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.gallery {
  top: 0;
  z-index: 1001;
  .title {
    font-family: $title-font;
    width: 100%;
    margin-top: 40px;
    text-align: center;
    color: $primary-tone-light;
  }
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(8, 20, 1, 0.95);
  .header {
    button {
      cursor: pointer;
      position: absolute;
      right: 4px;
      top: 4px;
      color: $primary-tone-light;
      background: rgba(0,0,0,0);
      box-shadow: none;
      border: none;
      padding: 20px;
      border-radius: 50px;
      outline: none;
      &:hover {
        background-color: rgba(255,255,255,0.05);
        text-shadow: 0px 0px 5px white;
      }
      i {
        font-size: 2rem;
      }
    }
  }
  .item {
    &.image {
      width: auto;
      height: calc(85vh - 250px);
    }
    border: 10px solid white;
    margin: 0px auto 30px auto;
    display: block;
  }
  .thumbnail {
    @media screen and (max-width: $screen-small) {
      min-height: 10vh;
    }
    width: 20vw;
    min-height: 20vh;
    margin: 10px;
    background-position: center;
    background-size: cover;
  }
  .videoThumbnail {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    video {
      border-radius: 10px;
    }
    .videoOverlay {
      color: white;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      &:hover {
        .videoOverlayIcon {
          transform: scaleX(1.6, 1.1);
        }
      }
      .videoOverlayIcon {
        transform: scaleX(1.5);
        background: rgba(18, 104, 33, 0.5);
        border-radius: 10px;
        padding: 20px;
        font-size: 2rem;
        position: relative;
        
      }
    }
  }
}
